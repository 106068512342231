
/* Please see documentation at https://docs.microsoft.com/aspnet/core/client-side/bundling-and-minification
for details on configuring this project to bundle and minify static web assets. */


@import url('https://fonts.googleapis.com/css2?family=Assistant&display=swap');
:root {
  --green-blikemah: #cfebac;
}
.btn-primary {
  --bs-btn-color: var(--green-blikemah);
  --bs-btn-bg: black;
  --bs-btn-border-color: #0d6efd;
  --bs-btn-hover-color: black;
  --bs-btn-hover-bg: var(--green-blikemah);
  --bs-btn-hover-border-color: #0a58ca;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0a58ca;
  --bs-btn-active-border-color: #0a53be;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #0d6efd;
  --bs-btn-disabled-border-color: #0d6efd;
}

.btn-success {
  --bs-btn-color: black;
  --bs-btn-bg: #d6e1b3;
  --bs-btn-border-color: black;
  --bs-btn-hover-color: var(--green-blikemah);
  --bs-btn-hover-bg: black;
  --bs-btn-hover-border-color: #146c43;
  --bs-btn-focus-shadow-rgb: 60, 153, 110;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #146c43;
  --bs-btn-active-border-color: #13653f;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #d6e1b3;
  --bs-btn-disabled-border-color: #d6e1b3;
}

.w-33 { width: 33%; }

html {
  font-size: 16px;
  position: relative;
  min-height: 100%;
}


body {
  /* Margin bottom by footer height */
  font-family: 'Assistant', 'sans-serif';
  padding-bottom: 60px;
  text-align: right;
  background-color: white;
  direction: rtl;
}

h1 {
  text-align: center;
}


.line-decoration {
  display: flex;
  align-items: center;
  text-align: center;
  margin: 10px 0;
}

.line-decoration::before,
.line-decoration::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid gray;  /* Line style */
  margin: 0 10px;
}

.line-decoration span {
  padding: 0 10px;
  font-weight: bold;  /* Customize title text style */
}



label {color: darkgreen;}

button { 
  border: none; 
  background: none;
}

button.remove {
  border: none;
  padding: 7px;
  width: 24px;
  height: 24px;
  background-color: transparent;
  background-image: url('data:image/svg+xml, <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg"><path d="m16.5 33.6 7.5-7.5 7.5 7.5 2.1-2.1-7.5-7.5 7.5-7.5-2.1-2.1-7.5 7.5-7.5-7.5-2.1 2.1 7.5 7.5-7.5 7.5ZM24 44q-4.1 0-7.75-1.575-3.65-1.575-6.375-4.3-2.725-2.725-4.3-6.375Q4 28.1 4 24q0-4.15 1.575-7.8 1.575-3.65 4.3-6.35 2.725-2.7 6.375-4.275Q19.9 4 24 4q4.15 0 7.8 1.575 3.65 1.575 6.35 4.275 2.7 2.7 4.275 6.35Q44 19.85 44 24q0 4.1-1.575 7.75-1.575 3.65-4.275 6.375t-6.35 4.3Q28.15 44 24 44Zm0-3q7.1 0 12.05-4.975Q41 31.05 41 24q0-7.1-4.95-12.05Q31.1 7 24 7q-7.05 0-12.025 4.95Q7 16.9 7 24q0 7.05 4.975 12.025Q16.95 41 24 41Zm0-17Z"/></svg>');
}

img {
  max-width: 100%;
}

  img.thumb {
      max-width: 36px;
  }


/* Provide sufficient contrast against white background */
a {
  color: black;
  text-decoration: none;
}

.text-danger { 
  font-weight: bold;
  /* display:none;  */
}

.hidden { display: none;}


table tr {
  vertical-align: middle;
  background-color: transparent;
}

#page {
  /* min-height: 100vh; */
  padding-top: 60PX;
  padding-bottom: 60px;
  margin-bottom: -60px;
}

.desktop {
  display: none;
}


.wrapper {
  margin: 0;
  /* padding: .5rem; */
} 

@media only screen and (min-width: 768px) {
  .wrapper {
    margin: 2rem;
    padding: 2rem;
    border: 1px solid gray;
    max-width: 600px;
    margin: auto;
  } 
  
  }


.header {
  background-color: var(--green-blikemah);
  color: black;
}

.mobile-header {
  background: var(--green-blikemah);
  text-align: center;
  color: #fff;
  line-height: 44px;
  width: 100%;
  position: fixed;
  top: 0;
}

  .mobile-header img {
      max-height: 50px;
      position: absolute;
      top: 5px;
      left: 5px;
  }


  
/* Banners  */

.banner img {
  width: 100%;
}

.carousel-item::before {
  /* background: rgba(0, 0, 0, 0.4); */
  position: absolute;
  left: 0;
  top: 0;
  content: '';
  width: 100%;
  height: 100%;
}

.carousel-caption {
  text-shadow: 2px 2px 4px #000000;
}

/* Homepage */
.category-box {
  position: relative;
  margin: 10px;
  max-width: 40%;
}

.category-box::before {
background: rgba(0, 0, 0, 0.1);
    position: absolute;
    left: 0;
    top: 0;
    content: '';
    width: 100%;
    height: 100%;
}

.category-text {
  font-size: 32px;
  font-weight: 400;
  color: black;
  /* background-color: var(--green-blikemah); */
  background-color: lightgray;
  padding: 0 10px;
}



.fancybox-close-small {
  position: absolute;
  top: -18px;
  right: -18px;
  width: 36px;
  height: 36px;
  cursor: pointer;
  background-image: url('../../public/img/fancybox_sprite.png');
  opacity: 1;
}

/*  Login  */



/* Payment  */
.ccard {
  height: 500px;
  border: none;
  padding: 30px 0;
  width: 100%;
	}

/**** Cart  ****/


.order-items .remove {
  min-width: 24px;
  height: 24px;
  /* background-image: url('data:image/svg+xml, <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg"><path d="m16.5 33.6 7.5-7.5 7.5 7.5 2.1-2.1-7.5-7.5 7.5-7.5-2.1-2.1-7.5 7.5-7.5-7.5-2.1 2.1 7.5 7.5-7.5 7.5ZM24 44q-4.1 0-7.75-1.575-3.65-1.575-6.375-4.3-2.725-2.725-4.3-6.375Q4 28.1 4 24q0-4.15 1.575-7.8 1.575-3.65 4.3-6.35 2.725-2.7 6.375-4.275Q19.9 4 24 4q4.15 0 7.8 1.575 3.65 1.575 6.35 4.275 2.7 2.7 4.275 6.35Q44 19.85 44 24q0 4.1-1.575 7.75-1.575 3.65-4.275 6.375t-6.35 4.3Q28.15 44 24 44Zm0-3q7.1 0 12.05-4.975Q41 31.05 41 24q0-7.1-4.95-12.05Q31.1 7 24 7q-7.05 0-12.025 4.95Q7 16.9 7 24q0 7.05 4.975 12.025Q16.95 41 24 41Zm0-17Z"/></svg>'); */
  display: none;
}


@media only screen and (max-width: 767px) {
  
  .order-items .remove.cellular {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.modal-header .btn-close {
margin-left: 0;
margin-right: auto;
}

@media only screen and (min-width: 768px) {
  
  .order-modal {
    width: 80vw;
    max-width: 80vw;
  }

  .order-modal .modal-content {
    padding: 15px;
  }
  
  .order-items .remove.desktop {
    display: table-cell;
  }
}



.vertical-nav ul {
  list-style-type: none;
}

.vertical-nav li {
  border-radius: 5%;
  background-color: #c4cd96;
  margin: 15px 10px;
  text-align: center;
}

.order-items .image img {
  max-width:50px;
  max-height:50px;
}

footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  white-space: nowrap;
  background-color: #d6e1b3;
  font-size: 0.8rem;
  /* display: flex;
  justify-content: center;
  flex-wrap: wrap; */
}

footer .logo {
  padding: 10px;
  max-width: 150px;
  /* background-color: black; */
}


.container {
  max-width: 1600px;
}


@media only screen and (min-width: 768px) {
  #page {
      padding-top: 173px;
      padding-bottom: 250px;
  }

  
  .desktop {
      display: block;
  }

  .desktop.cell {
    display: table-cell;
  }

  .mobile-header {
      display: none;
  }

  footer .BottomBar {
    padding-top: 30px;
    margin: 0;
  }
  
  footer .BottomBar .BottomCategories a {
    width: 120px;
    display: inline-block;
    padding-top: 5px;
    font-size: 0.9rem;
  }
  
  .BottomBar ul {
    margin: 0;
    padding: 0;
    padding-left: 32px;
    min-height: 91px;
  }

  .BottomBar li {
    list-style-type: none;
  }
}


  button.green, .button.green {
      color: white;
      background-color: green;
  }

  .button.green:hover {
      color: yellow;
  }

.add {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" fill="navy" class="bi bi-plus-square" viewBox="0 0 16 16"> <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/> <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/></svg>');
  height: 22px;
}

.delete {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" fill="red" class="bi bi-x-square" viewBox="0 0 16 16"> <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/> <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/> </svg>');
  height: 24px;
  width: 24px;
  vertical-align: text-bottom;
  border: none;
  cursor: pointer;
}

.close {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" viewBox="0 0 48 48"><path d="m12.45 37.65-2.1-2.1L21.9 24 10.35 12.45l2.1-2.1L24 21.9l11.55-11.55 2.1 2.1L26.1 24l11.55 11.55-2.1 2.1L24 26.1Z"/></svg>');
  min-width: 24px;
  height: 24px;
  padding: 0;
  border: none;
}

  .close:hover {
      background-color: lightgray;
  }

.recycle {
  background-image: url('/public/img/recycle.png');
  height: 24px;
  width: 24px;
  vertical-align: text-bottom;
  border: none;
}


.bi-x-square {
  color: red;
  font-size: 22px;
}

.ui-sortable > tr {
  cursor: grab;
}

/* Navigation bar */
.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}



@media only screen and (min-width: 1201px) {
  .navbar-nav {
      font-size: 16px;
      margin: 0 0;
      display: flex;
      justify-content: space-around;
      padding: 5px 0;
  }
}


.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}


.buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between !important;
}

.btn {
  min-width: 100px;
}


/*********************************/



/*********************************/



.module {
  width: 160px;
  height: 160px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  transition: background .5s ease-out;
  border: 1px solid lightslategray;
}





/*  autocomplte complementary items */
.ui-menu-item-wrapper img {
  max-width: 40px;
}

/* orders and order items  */
#order-items, #my-orders {
  width: 100%;
  overflow: hidden;
  max-height: 0;
  transition: all 1s ease;
}



/** desktop header  **/
/** top menu */

header {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 5;
  background-color: white;
  -webkit-box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.28);
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.28);
}

@media only screen and (min-width: 1201px) {
  header {
      display: block;
  }
}

.headerTop {
  background-color: #efede9;
  width: 100%;
  font-weight: bold;
}

.headerRight {
  width: 300px;
}

header .logo img {
  max-width: 180px;
}

header .user-picture {
  width: 38px;
  height: 38px;
}
.header-top {
  background-color: var(--green-blikemah);
  font-size: 1.1rem;
}

.header-top  a {
  color: black;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 1.1rem;
  text-decoration: none;
}


.topmenu {
  font-size: 14px;
  width: calc(100% - 425px);
  display: flex;
  align-items: flex-end;
}

  .topmenu div.menuitem {
      padding: 3px 8px;
      width: 300px;
  }

      .topmenu div.menuitem a {
          line-height: 45px;
          white-space: nowrap;
          margin: 0 15px;
      }




/**  search **/

.headerSearchFront {
  /* height: 45px; */
  background-color: white;
  border: 1px solid gray;
  color: black;
  text-align: right;
  position: relative;
  padding: 10px;

}

.searchBar {
  background-color: white;
  position: absolute;
  z-index: 10;
  list-style-type: none;
  width: 600px;
  padding: 15px;
}

.searchBar li {
  margin-bottom: 10px;
  cursor: pointer;
}

.searchBar li img {
  width: 60px;
  padding-left: 10px;
}
.searchBar li:hover {
  border: 1px solid gray
}



#searchInputDesktop, #searchInputSmart {
  /* height: 36px; */
    border: none;
    font-size: 1.1rem;
    outline: none;
    background-color: transparent;
    width: 300px;
    padding: 0 10px 0 40px;
}

.headerSearchSubmit {
  width: 36px;
  height: 100%;
  border: none;
  background: transparent url('/public/img/search-icon.png') no-repeat center center;
  cursor: pointer;
  position: absolute;
  left: 0;
  top: 0;
}

/* End of search */



/* categories menu */

/* remove dropdown arrow */
#topnav {
  width: 100%;
}

.dropdown-toggle::after {
  content: none;
}

a.nav-link {
  padding: 16px;
  color:white !important;
}



.headerLeft .totals {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 225px;
  padding: 18px 0;
  background-color: var(--green-blikemah);
  border-radius: 10px;
  margin: 5px;
}

a.cart-icon {
  text-decoration: none;
}

.totals #TotalItems {
  position: absolute;
  left: 15px;
  top: -6px;
  min-width: 24px;
  background-color: #eb4f22;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  color: white;
  font-weight: bold;
  text-align: center;
}

.TotalPrice {
  border-top: 1px solid #591828;
  border-bottom: 1px solid #591828;
  text-align: center;
  padding: 4px 0;
}

#TotalPrice:after
{
    content: " ₪";
}

@media only screen and (max-width: 1200px) {
  .totals .cart-icon {
      height: 60px;
      padding: 12px 0;
  }
}


/* ?? */
#topmenu {
  z-index: 99;
  width: 100%;
}



.SmartSearch {
  position: fixed;
  top: 60px;
  z-index: 103;
  height: 38px;
  width: 100%;
  background-color: white;
}
/* end of top line links */




/*   begin side cart  **/

.mini-cart {
  position: fixed;
  top: 0;
  left: 0;
  width: 450px;
  padding: 15px;
  margin-left: -450px;
  color: #591828;
  background-color: #efede9;
  height: 100%;
  overflow: hidden;
  z-index: 10;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

  .mini-cart.open-cart {
      margin-left: 0;
  }


.overlay.body_overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
  opacity: 0;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  z-index: 7;
  background: rgba(0, 0, 0, 0.5);
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.open-cart .overlay.body_overlay {
  visibility: visible;
  opacity: 1;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
}


.mini-cart .cart-top {
  text-align: right;
  padding: 0 15px 15px 15px;
}

  .mini-cart .cart-top .title {
      font-size: 21px;
      font-weight: bold;
  }

  .mini-cart .cart-top span {
      font-size: 16px;
  }

.mini-cart .sidecart {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  overflow: auto;
  height: calc(100% - 80px);
}

  .mini-cart .sidecart #mini-cart-total-sml {
      position: absolute;
      top: -50px;
      left: 0;
      background-color: #6ea442;
      color: white;
      border-radius: 50%;
      padding-top: 23px;
      font-size: 11pt;
      width: 60px;
      height: 60px;
      text-align: center;
  }

.mini-cart .sidecart {
  width: 100%;
  margin: auto;
  list-style: none;
  overflow: auto;
}

  .mini-cart .sidecart #items {
      overflow: auto;
      flex-grow: 1;
  }

      .mini-cart .sidecart #items ul {
          margin: auto;
          padding: 0;
          font-size: 12pt;
          padding:10px;
      }

          .mini-cart .sidecart #items ul li {
              background-color: white;
              padding: 5px;
              margin-bottom: 5px;
          }

              .mini-cart .sidecart #items ul li .item {
                  position: relative;
                  display: flex;
                  flex-wrap: wrap;
                  justify-content: space-between;
                  padding: 10px;
              }

                  .mini-cart .sidecart #items ul li .item .thumb {
                      width: 60px;
                      height: 60px;
                      text-align: center;
                  }

                  .mini-cart .sidecart #items ul li .item .content {
                      white-space: normal;
                      flex-grow: 1;
                      padding: 0 10px;
                      max-width: 270px;
                  }

                  .mini-cart .sidecart #items ul li .item .notes {
                      width: 100%;
                  }

                  .mini-cart .sidecart #items ul li .item .remove {
                      position: absolute;
                      padding: 0;
                      top: -10px;
                      left: -10px;
                      z-index: 10;
                      /* min-width: 24px;
                      height: 24px;
                      border: none;
                      background-color: transparent;
                      background-image: url('data:image/svg+xml, <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg"><path d="m16.5 33.6 7.5-7.5 7.5 7.5 2.1-2.1-7.5-7.5 7.5-7.5-2.1-2.1-7.5 7.5-7.5-7.5-2.1 2.1 7.5 7.5-7.5 7.5ZM24 44q-4.1 0-7.75-1.575-3.65-1.575-6.375-4.3-2.725-2.725-4.3-6.375Q4 28.1 4 24q0-4.15 1.575-7.8 1.575-3.65 4.3-6.35 2.725-2.7 6.375-4.275Q19.9 4 24 4q4.15 0 7.8 1.575 3.65 1.575 6.35 4.275 2.7 2.7 4.275 6.35Q44 19.85 44 24q0 4.1-1.575 7.75-1.575 3.65-4.275 6.375t-6.35 4.3Q28.15 44 24 44Zm0-3q7.1 0 12.05-4.975Q41 31.05 41 24q0-7.1-4.95-12.05Q31.1 7 24 7q-7.05 0-12.025 4.95Q7 16.9 7 24q0 7.05 4.975 12.025Q16.95 41 24 41Zm0-17Z"/></svg>'); */
                  }

  .mini-cart .sidecart .button {
      text-decoration: none;
      font-weight: bold;
  }

  .mini-cart .sidecart #items ul li .item input {
      font-size: 12pt;
      height: 30px;
      line-height: 26px;
      padding-top: 5px;
      border: 0;
      border-top: 1px solid #cacaca;
      color: darkred;
      display: block;
      width: 100%;
      padding: 0 7px;
      -webkit-transition: all 0.3s ease;
      -moz-transition: all 0.3s ease;
      -ms-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      transition: all 0.3s ease;
  }

  .mini-cart .sidecart .carttotal {
      font-weight: bold;
      font-size: 16px;
      padding: 15px 0;
  }


  .mini-cart .sidecart #carttotal {
      padding: 5px 8px;
      display: block;
      width: 230px;
      margin: auto;
      border-top: 1px solid #34322f;
  }

  .mini-cart .sidecart #carttotals .TotalPrice {
      width: 100%;
      font-size: 27pt;
      font-weight: 600;
      color: #4a4a49;
      text-align: center;
  }

      .mini-cart .sidecart #carttotals .TotalPrice a {
          text-decoration: none;
      }

/** end side cart  **/


/** Side cart */

#carttable li {
    position: relative;
}

    #carttable li .notes {
        font-weight: bold;
        color: red;
        font-size: .9rem;
    }

     .mini-cart .actions {
        visibility: hidden;
        position: absolute;
        /* background-color: rgba(0, 0, 0, .6); */
        background-color: white;
        bottom: 2px;
        width: 98%;
        height: 80px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        align-content: space-evenly;
        justify-content: space-evenly;
        padding: 5px;
    }

     .mini-cart li:hover .actions {
        visibility: visible;
        opacity: 1;
    }

    #carttable li .ItemNotes {
        width: 100%;
        padding: 5px 5px 0 5px;
    }

        #carttable li .ItemNotes input {
            width: 100%;
            line-height: 19px;
        }


/* Cash windows  */

.cash {
  max-width: 900px;

  margin: auto;
  background-color: white;
  margin-top: 25px;
}
