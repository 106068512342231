  
/* nav:not(.mm-menu) {
	display: none;
} */

/* .mmenu-toggle {
	position: fixed;
	top: 10px;
	right: 10px;
	z-index: 1000;
  } */

  
header.smart,
.footer {	
	text-align: center;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    height: 60px;
    background-color: lightgreen;
    position: fixed;
    top: 0;
    z-index: 999;
    -webkit-box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.28);
    box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.28);
}


header.smart .search-icon { 
	z-index: 2;
	padding-top: 10px;
}

header.smart .basket-icon {padding: 12px;}
header.smart .basket-icon #smart-total {
	position: absolute; 
	top:3px; 
	right:0; 
	padding:6px; 
	height:21px; 
	padding-top:1px;
	background-color:#eb4f22;
	border-radius:50%;
	-webkit-border-radius:10px;
	-moz-border-radius:10px;
	color:white;
	font-weight:bold;
	display:none;
}

header.smart .search-icon {
	width: 54px; 
}

header.smart .basket-icon {	
position: relative;
    width: 57px;
    padding-top: 17px !important;
}

header.smart a.logo {
	height:72%;
}

header.smart a.logo img {
max-width: 100%;
max-height: 100%;
}


/**  mmenu customization  **/

.mm-menu {
	--mm-color-text: #402212;
	--mm-listitem-size: 35px;
	}

/* right side hamburger */
header.smart a.menu_btn {
    width: 28px;
    padding: 20px;
	box-sizing: content-box;
}
header.smart a.menu_btn:before,
header.smart a.menu_btn:after {
	content: '';
    display: block;
    background: #213519;
    height: 3px;
}
header.smart a.menu_btn span {
	background: #213519;
    display: block;
    height: 3px;
    margin: 6px 0;
}

.mm-menu a, .mm-menu a:active, .mm-menu a:hover, .mm-menu a:link, .mm-menu a:visited {
	height: auto;
    font-weight: bold;
    padding: 15px 30px;
	}
	
.mm-listview {
	text-align: right;
	}

.mm_listitem_text {
	padding-left: 10px;
	padding-right: 20px;
	}
a.top {
	position: relative;
	/* background-color:#f8b433; */
	text-align: center;
}

.mm-navbar_sticky {  background-color: #f9b613; color:black;}

.mm-navbar__breadcrumbs {
	align-items: center;
	display: flex;
	justify-content: right;
}

.mm-spn li:before ,
.mm-btn_next:after {
	-webkit-transform: rotate(-135deg);
	-ms-transform: rotate(-135deg);
	transform: rotate(-135deg);
	left: 23px;
	right: auto;
	}
	
	.mm-spn li:after {
		margin-left: 0;
	}

	.mm-spn span {
		padding: 5px 0;
	}
.mm-btn_prev:before {
	-webkit-transform: rotate(135deg);
	-ms-transform: rotate(135deg);
	transform: rotate(135deg);
}

.mm-listitem:first-child::after {border-bottom:none;}
.mm-listitem:nth-child(2)::after {border-bottom:none;}


.mm-panel:after {
	display:none;
	}
.mm-navbar__breadcrumbs {
	display:none;
	height: 10px;
	}
.mm-navbar__btn:not(.mm-hidden)+.mm-navbar__title:last-child {
	padding-right: 0px;
	}
/*  remove header.smart from first menu  */
.mm-panel:first-child > .mm-navbar_sticky {display: none;}

.mm-listitem a.m-login {
	display: flex;
	padding: 30px;
	}

.mm-listitem span.welcome {
    text-align: center;
    font-size: 13pt;
    font-weight: bold;
    line-height: 60px;
    color: #ee6421;
}
	
.mm-listitem.general > a {
	color:black !important;
	background-color: #b7c93f;
	display: flex;
	}


.icons-panel {
	background-color: var(--green-blikemah) !important;
}

.icons-panel > a {
    font-size: 10pt !important;
    padding: 17px 0 !important;
}

.icons-panel .fa {
    font-size: 15pt !important;
}
a.sale { color:red !important; }
	
.mm-listitem_opened {
	background-color: orange;
	border:none;
	width:90%;
	margin:auto;
	-webkit-box-shadow: 0px 2px 25px 0px rgba(0, 0, 0, 0.20);
	box-shadow: 0px 2px 25px 0px rgba(0, 0, 0, 0.20);
	}
	
.mm-listitem_opened > a:first-child {padding-right:16px;}
.mm-listitem_opened > .mm-btn {background-color: orange !important; border: none;}

.mm-listitem_opened>.mm-listitem__btn, .mm-listitem_opened>.mm-panel {
		background-color: white;
	}
.mm-listitem_opened.mm-listitem:after { border:none;}


.mm-listitem_opened>.mm-listitem__btn:after {
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
	right: auto;
	left:15px;
}

.mm-menu .drop-down {
	background-color: var(--green-blikemah) !important;
}