:root {
    --warning: red;
}
.Product {
    width: 150px;
    height: 360px;
    margin: 3px;
}
.card {
    padding: .5rem;
}

@media only screen and (min-width: 480px) {
    .Product {
        width: 250px;
        height: 360px;
        margin: 10px;
    }
    .card {
        padding: 1rem;
    }
    
}



.card h5 {
    font-size: 1.1rem;;
}

.card .card-title {
    height: 42px;
    width: 100%;
}

.card .card-img-top {
    height: 190px;
}

.card .card-body {
    padding: 0;
    margin-top: 10px;
    position: relative;
    display: flex;
    align-content: space-between;
    flex-wrap: wrap;
}

.card .card-body .sale-text {
    align-items: center;
    color: var(--warning);
    font-weight: 700;
    width: 100%;
}

.card .card-body .card-text {
    display: flex;
    align-items: flex-end;
    
    height: 32px;
}

.card .card-body .unit:before
{
    content: " ₪ ל";
}

.card .sale-due {
    justify-content: space-around;
    flex-direction: column;
    font-weight: bold;
    color: var(--warning);
    width: 100%;
    display: flex;
    align-items: center;
    font-size: small;
}


.card .sale-price {
    color: #e1001a;
    font-weight: 700;
    font-size: 1.2rem;    
    position: absolute;
    left: 0;
}

.card .labels {
    position: absolute;
    top: -12px;
    right: -12px;
    margin-left: 0;
}

.card .cart-qtty {
    position: absolute;
    left: -20px;
    bottom: 36px;
    width: 40px;
    background-color: green;
    color: white;
    padding: 5px;
    border-radius: 5px;
}

.card .small-text {
    width: 100%;
    align-items: center;
    font-size: small;
    margin: auto;
}

.card .small-text::after {
    content: " ל-100 גרם ";
}

.card .actions {
    position: absolute;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: space-evenly;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    visibility: hidden;
    opacity: 0;
    background-color: rgba(0,0,0,.6);
    transition: all .15s;
}

.card .card-body:hover .actions {
    visibility: visible;
    opacity: 1;
}


/** Qtty Action section **/

.actions .action-buttons {
    background-color: white;
    font-size: 24px;
    width: 132px;
}

.actions button {
    border: none;
    line-height: normal;
    border: none;
}

.actions .units {
    display: block;
    color: black;
    background-color: white;
    height: 100%;
    text-decoration: none;
    text-align: center;
    font-weight: 600;
}

.actions .units.active {
    background: #4c7f22;
    color: white;
}


.unit-types {
    background-color: white;
    width: 100px;
    height: 28px;
    line-height: 26px;
    font-weight: 600;
    border: 1px solid gray;
}

/** End of Qtty action section ***/


.category {
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.category .item {
    width: 210px;
    float: right;
    height: 295px;
    border: 1px solid #e8e8e8;
    position: relative;
    background-color: #fff;
    text-align: center;
    box-shadow: 0 2px 12px #e8e8e8;
    cursor: pointer;
    margin-bottom: 5px;
}

@media only screen and (max-width: 480px) {
    .category .item {
        width: 50%;
    }
}

.category .item .Image {
    position: relative;
    display: block;
    margin-bottom: 12px;
    padding: 35px 5px 5px;
    height: 173px;
    overflow: hidden;
    text-align: center;
}

    .category .item .Image img {
        max-height: 100%;
        max-width: 100%;
    }

.category .item .details {
    padding: 0 8px 10px;
}

.category .item .title {
    color: #4e8222;
    margin: 0;
    font-size: 14px;
    font-weight: 700;
    transition: color .15s;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    display: inline-block;
}

.category .item .item-price {
    margin-top: 11px;
    padding: 0 2px;
    text-align: left;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    flex-wrap: wrap;
    height: 36px;
    font-size: 0.9rem;
}

.category .item .sale-price {
    color: #e1001a;
    font-weight: 700;
    font-weight: 700;
    -webkit-box-flex: 2;
    -ms-flex: 2;
    flex: 2;
}

.category .item .price {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.category .item .price .bold {
    font-size: 16px;
    font-weight: 700;
}

.category .item .sale-price .bold {
    font-size: 24px;
}

.category .item .unit:before {
    content: " ₪ ל";
}

/* 
.unit-types {
    background-color: white;
    width: 100px;
    height: 28px;
    line-height: 26px;
    font-weight: 600;
    border: 1px solid gray;
} */


input.product_qty, input.ItemQtty {
    width: 40px !important;
    padding: 6px 1px !important;
    margin: 0 !important;
    text-align: center !important;
    border: none;
    background: white;
    font-size: 16px;
    line-height: normal;
}






.category .item .soldout {
    background-color: white;
    color: red;
    padding: 0 5px;
    display: block;
    text-align: center;
    margin-top: 7px;
    font-size: 14px;
    font-weight: bold;
    line-height: 30px;
    border-radius: 10px;
    border: none;
    width: 140px;
}

.category .item a {
    text-decoration: none;
}


.Product .product-info {
    position: absolute;
    left: -5px;
    top: -5px;
    width: 24px;
    height: 24px;
    z-index: 2;
}

    .Product .product-info .details {
        visibility: visible;
        z-index: 1004;
        visibility: hidden;
        position: absolute;
        width: 250px;
        min-height: 200px;
        top: 20px;
        left: 3px;
        text-align: right;
        color: black;
        font-size: 14pt;
        background-color: rgba(256, 256, 256, 0.8);
        border-radius: 8px;
        -webkit-border-radius: 8px;
        -moz-border-radius: 8px;
        padding: 15px;
        border: 1px solid #34322f;
    }

    .Product .product-info:hover .details {
        visibility: visible;
    }
    
    /* .Product .product-info:hover .close {
        cursor: pointer;
        position: absolute;
        left: 3px;
        top: 3px;
    } */


    /** Cart */
.PriceUnit:before
{
    content: " ל";
}


.ingredient {
    width: 300px;
    height: 130px;
}
.ingredient .name {
    width: 40%;
}
.ingredient .image {
    width: 40%;
}