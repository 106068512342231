/*
Desktop menu
*/

/* typography */
@media (min-width: 921px) {

    /*MAIN MENU*/
    /* .menu-toggle{
        display:block;
        padding:10px;
        background:#666;
        color:#fff;
        cursor:pointer;
        text-align: right;
    } */
    
    .mega-menu {
        margin:0;
        clear:both;
        display:none;
        padding: 0;
        font-size:16px;
        font-weight: bold;
    }
    
    .mega-menu.toggled-on {
        display:block;
        position:relative;
        z-index:10;
        text-align: right;
    }
    
    .mega-menu li {
        width: 25%;
        float:right;
        background-color:gray;
    }
    
    .mega-menu li > a {
        display:block;
        color:white;
        text-align: center;
        border: solid #F3F3F3;
        border-width: 0 1px 1px 0;
        padding: 19% 0 18% ;
        white-space: nowrap;
        overflow: hidden;
        text-decoration:none;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        width: 100%;
        -webkit-transition: background .2s ease;
        -moz-transition: background .2s ease;
        -o-transition: background .2s ease;
        transition: background .2s ease;
    }
    
    .mega-menu li a:hover {
        background:#0099ff; /* tchelet */
        color:white;
    }
    
    ul.mega-menu li > div {
        display:none;
    }
    
    .mega-menu li li a {
        padding-right:40px;
    }
    .mega-menu li li li a {
        padding-right:80px;
    }
    
    } /* media > 920 */
    
    /* Contain floats: h5bp.com/q */ 
    .clearfix:before, .clearfix:after { content: ""; display: table; }
    .clearfix:after { clear: both; }
    .clearfix { zoom: 1; }
    
    /*
    TABLET & SMALLER LAPTOPS
    The average viewing window and preferred media query for those is 768px.
    But I think that some more breathing space is good:)
    */
    
    
    /*** MAIN MENU - ESSENTIAL STYLES ***/
    .menu-toggle{display:none;}
    #menu-main-navigation{display:block;}
    
    .mega-menu, .mega-menu * {
        padding:		0;
        list-style:		none;
        text-align: 	right;
    }
    
    .mega-menu li li {
        margin-left:0px;
    }
    .mega-menu a {
        display:	block;
    }
    
    .mega-menu ul li {
        width:		100%;
    }
    .mega-menu > div:hover > div,
    .mega-menu > div.sfHover > div {
        display:block;
        z-index:		99;
    }
    
    
    /*** header desktop categories menu ***/
    
    .mega-menu {
        width: 100%;
        position: relative;
        display: flex;
        justify-content: space-around;
        align-items: center;
        align-content: center;
        height: auto;
        }
        
    .mega-menu > div {
        float:right;
        background-color:transparent;
        width: auto;
        position:relative;
        padding: 11px;
        height: 100%;
        display: grid;
        align-items: center;
    }
    
    .mega-menu > div > a {
        color: black;
        font-size: 1.1rem;
        font-weight: bold;
        white-space: nowrap;
        border-bottom: 5px solid transparent;
        text-decoration: none;
        text-wrap: wrap;
        line-height: normal;
    }
    
    .mega-menu > div > a:hover, .mega-menu > div > a:active {
        border-bottom: 5px solid #d41a21;

    }
    .mega-menu > div a.current {
        color: #043e23;	
        border-bottom: 5px solid #d41a21;
        background-color: transparent;
    }
/*     .mega-menu > div:last-child a {
        color:  #d41a21;
        } */
    
    .mega-menu li  li {
        width:auto;
        padding: 14px 0;
        display: inline;
        height: 14px;
        line-height: 14px;
        float:right;
        }
    
    .mega-menu li li a {
        padding-right:20px;
        height:auto;
        font-size:14px;
        line-height:16px;
        }
    
    .mega-menu li li:last-child a {
        background-color: transparent;
        }
    

    
    /*
    DESKTOP
    This is the average viewing window. So Desktops, Laptops, and
    in general anyone not viewing on a mobile device. Here's where
    you can add resource intensive styles.
    */
    @media only screen and (min-width: 1030px) {
    
    
    } 
    
    /*
    LARGE VIEWING SIZE
    This is for the larger monitors and possibly full screen viewers.
    */
    @media only screen and (min-width: 1240px) {
    
    } 
    
    /*
    RETINA (2x RESOLUTION DEVICES)
    This applies to the retina iPhone (4s) and iPad (2,3) along with
    other displays with a 2x resolution.
    */
    @media only screen and (-webkit-min-device-pixel-ratio: 1.5),
           only screen and (min--moz-device-pixel-ratio: 1.5),
           only screen and (min-device-pixel-ratio: 1.5) {
    
    
    } 
    
    /*
    PRINT STYLESHEET
    */
    @media print {
      * { background: transparent !important; color: black !important; text-shadow: none !important; filter:none !important; -ms-filter: none !important; } /* Black prints faster: h5bp.com/s */
      a, a:visited { text-decoration: underline; }
      a[href]:after { content: " (" attr(href) ")"; }
      abbr[title]:after { content: " (" attr(title) ")"; }
      .ir a:after, a[href^="javascript:"]:after, a[href^="#"]:after { content: ""; }  /* Don't show links for images, or javascript/internal links */
      pre, blockquote { border: 1px solid #999; page-break-inside: avoid; }
      thead { display: table-header-group; } /* h5bp.com/t */
      tr, img { page-break-inside: avoid; }
      img { max-width: 100% !important; }
      @page { margin: 0.5cm; }
      p, h2, h3 { orphans: 3; widows: 3; }
      h2, h3 { page-break-after: avoid; }
    }
    
    .menu_item:hover .menu_drop, .menu_item.active .menu_drop {
        display: block;
    }
    
    .menu_drop {
        display: none;
        min-width: 330px;
        min-height: 222px;
        position: absolute;
        top: 50px;
        right: -20px;
        background: #fff;
        z-index: 99;
        border-radius: 0 0 20px 20px;
        -webkit-box-shadow: 0px 2px 25px 0px rgba(0,0,0,.2);
        box-shadow: 0px 2px 25px 0px rgba(0,0,0,.2);
        overflow: hidden;
        padding: 15px;
    }
    
    .drop_link a {
        display: block;
        color: #213519;
        line-height: 19px;
        margin-bottom: 10px;
        white-space: nowrap;
        text-decoration: none;
    }
    
    .drop_img {
        width: 150px;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
    }
    .cover {
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
    }
    .drop_title {
        font-size: 22px;
        color: #b6cc8c;
        font-weight: bold;
        margin-bottom: 12px;
        white-space: nowrap;
    }
    .column-2 {
        -webkit-column-count: auto;
        column-count: auto;
            display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        height: 150px;
    }
    .drop_col {
        width: 48%;
    }



    /*** addition for mmenu ****/
    .mmenu-toggle {
        position: fixed;
        top: 10px;
        right: 10px;
        z-index: 1000;
      }
      
      .mmenu-toggle button {
        background: none;
        border: none;
        cursor: pointer;
        padding: 10px;
      }
      
      .hamburger {
        display: block;
        width: 25px;
        height: 2px;
        background-color: #333;
        position: relative;
      }
      
      .hamburger::before,
      .hamburger::after {
        content: '';
        width: 25px;
        height: 2px;
        background-color: #333;
        position: absolute;
        left: 0;
      }
      
      .hamburger::before {
        top: -8px;
      }
      
      .hamburger::after {
        top: 8px;
      }
      